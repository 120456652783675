/**




*/
import Vue from "vue";
import {
  Input,
  Select,
  FormModel,
  DatePicker
} from "ant-design-vue";
Vue.use(Input).use(Select).use(FormModel).use(DatePicker);