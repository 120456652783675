<template>
  <div id="Login">
    <a-form-model
      ref="form-login"
      :model="form.item"
      :rules="form.rules"
      v-bind="{
        labelCol: { span: 0 },
        wrapperCol: { span: 24 },
      }"
    >
      <a-form-model-item has-feedback prop="admin">
        <a-input
          v-model="form.item.admin"
          autocomplete="off"
          :placeholder="'请输入账户'"
        >
          <a-icon slot="prefix" type="user" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item has-feedback prop="password">
        <a-input-password
          v-model="form.item.password"
          autocomplete="off"
          :placeholder="'请输入密码'"
        >
          <a-icon slot="prefix" type="lock" />
        </a-input-password>
        <router-link
          id="forget-the-password"
          :to="{ name: 'Forget-Password' }"
          :style="{
            display: 'inline-flex',
            height: '1.2em',
            lineHeight: ' 1.2em',
            float: 'right',
            color: '#ccc !important',
          }"
        >
          忘记密码
        </router-link>
      </a-form-model-item>
      <a-form-model-item>
        <a-button
          id="btn-login"
          @click="form_submit()"
          ref="formBtn"
          type="primary"
        >
          登录帐号
        </a-button>
        <a-button id="register" @click="$router.push({ name: 'Register' })">
          前往注册
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>

</template>
<script>
import "@/components/js/_form_common.js";

export default {
  data: () => {
    return {
      form: {
        item: {
          admin: "",
          password: "",
        },
        rules: {
          admin: [
            {
              validator: (rule, value, callback) => {
                if (value === "") {
                  callback(new Error("请输入您的账户"));
                } else {
                  callback();
                }
              },
              trigger: ["change", "blur"],
            },
          ],
          password: [
            {
              validator: (rule, value, callback) => {
                if (value === "") {
                  callback(new Error("请输入您的密码"));
                } else {
                  callback();
                }
              },
              trigger: ["change", "blur"],
            },
          ],
        },
      },
    };
  },
  methods: {
    form_submit() {
      this.$refs["form-login"].validate((valid) => {
        if (valid) this.$Api.post().Login(this.form.item);
      });
    },
  },
};
</script>
<style lang="less" scoped>
#User-Login {
  > div {
    width: 100%;
    flex: 0 0 auto;

    &.login-logo {
      // max-height: 100%;
      text-align: center;

      img {
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &.login-title {
      font-size: 1.5em;
      padding-top: 0.5em;
      margin-bottom: 0.5em;
    }

    &.login-form {
      background-color: #3d447b;
      padding: 2em 2em;
      border-radius: 0.2rem;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;

      input {
        // background-color: rgba(0, 0, 0, 0);
        border-radius: 0;
        color: white;
      }

      .ant-input-affix-wrapper {
        > .ant-input-prefix,
        > .ant-input-suffix {
          color: white;

          > .ant-input-password-icon {
            color: inherit;
          }
        }
      }

      .AutomaticLanding {
        width: 100%;
        // text-align: right;
        display: inline-flex;
        justify-content: flex-end;
        align-content: center;
        align-items: center;

        // margin-top: -24px;

        .ant-btn {
          // height: 1em;
          width: auto;
          border: 0;
          margin: 0;
          padding: 0;
          display: inline-block;
        }

        * {
          color: #acacac;
        }
      }

      button {
        width: 100%;
        line-height: 2em;
        border-radius: 0.5em;
        margin-bottom: 0.1rem;

        &#btn-login {
          color: white;
          border: 0;

          &:not([disabled="disabled"]) {
            background-color: #1668f7;
          }
        }

        &#btn-register {
        }
      }
    }
  }
}
</style>
