var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Login"}},[_c('a-form-model',_vm._b({ref:"form-login",attrs:{"model":_vm.form.item,"rules":_vm.form.rules}},'a-form-model',{
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
    },false),[_c('a-form-model-item',{attrs:{"has-feedback":"","prop":"admin"}},[_c('a-input',{attrs:{"autocomplete":"off","placeholder":'请输入账户'},model:{value:(_vm.form.item.admin),callback:function ($$v) {_vm.$set(_vm.form.item, "admin", $$v)},expression:"form.item.admin"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-model-item',{attrs:{"has-feedback":"","prop":"password"}},[_c('a-input-password',{attrs:{"autocomplete":"off","placeholder":'请输入密码'},model:{value:(_vm.form.item.password),callback:function ($$v) {_vm.$set(_vm.form.item, "password", $$v)},expression:"form.item.password"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1),_c('router-link',{style:({
          display: 'inline-flex',
          height: '1.2em',
          lineHeight: ' 1.2em',
          float: 'right',
          color: '#ccc !important',
        }),attrs:{"id":"forget-the-password","to":{ name: 'Forget-Password' }}},[_vm._v(" 忘记密码 ")])],1),_c('a-form-model-item',[_c('a-button',{ref:"formBtn",attrs:{"id":"btn-login","type":"primary"},on:{"click":function($event){return _vm.form_submit()}}},[_vm._v(" 登录帐号 ")]),_c('a-button',{attrs:{"id":"register"},on:{"click":function($event){return _vm.$router.push({ name: 'Register' })}}},[_vm._v(" 前往注册 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }